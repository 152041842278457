@import 'styles/Fade.scss';

.flex-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
}

.image {
  width: 75%;
  height: 75%;
  @media only screen and (min-width: 768px) {
    width: 50%;
    height: 50%;
  }
  @media only screen and (min-width: 992px) {
    width: 25%;
    height: 25%;
  }
  @media only screen and (min-width: 1200px) {
    width: 20%;
    height: 20%;
  }
}

.title {
  font-family: 'Aladin', cursive;
  font-size: 5rem;

  @media only screen and (min-width: 768px) {
    font-size: 6rem;
  }
  @media only screen and (min-width: 992px) {
    font-size: 7rem;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 8rem;
  }
}

#fade-in {
  @include animation('fadein 2.5s');
}

@include keyframes(fadein) {
  from { opacity: 0; }
  to   { opacity: 1; }
}
