@mixin animation($animate...) {
  $max: length($animate);
  $animations: '';

  @for $i from 1 through $max {
    $animations: #{$animations + nth($animate, $i)};

    @if $i < $max {
      $animations: #{$animations + ", "};
    }
  }

  animation: $animations;
}

@mixin keyframes($animationName) {
  @keyframes #{$animationName} {
    @content;
  }
}
